import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import minABI from '../Tokens/minABI';
import axios from 'axios';
import 'highlightjs/styles/atom-one-dark.css';
var lineColumn = require("line-column");
var hljs = require('highlightjs');
var hljsDefineSolidity = require('highlightjs-solidity');


hljsDefineSolidity(hljs)
hljs.initHighlightingOnLoad()

class Analyze extends Component {
    constructor(props) {
        super(props);
    }

    getDecimalsNew = async () => {
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.decimals().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenDecimals: 100
                        });
                    } else {
                        app.setState({
                            TokenDecimals: 0
                        });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    call_function = async (signature) => {
        try {
            let app = this;
            console.log(this.props.token + "signature: " + signature);
            await window.web3.eth.call({
                to: this.props.token,
                data: signature
            })
                .then((response, err) => {
                    if (response) {
                        // if first 64 bytes a
                        if (response.length > 66 && response.substring(0, 66) == "0x0000000000000000000000000000000000000000000000000000000000000020") {
                            // remove first 66 characters & parse to hex, add 0x again for web3 library to parse hex
                            let utf8_string = window.web3.utils.hexToUtf8("0x"+response.substring(66));
                            // remove first character because of the 0x
                            document.getElementById(signature).innerText = utf8_string.substring(1);
                        } else if (
                            (response.substring(0,26) == "0x000000000000000000000000" &&
                            // check for more zeros, if the address doesn't start with 000000 (6 zeros) we parse it as address, else it's u256
                            response.substring(0,32) != "0x000000000000000000000000000000") ||
                            // response is not dead wallet
                            response == "0x000000000000000000000000000000000000000000000000000000000000dead") {
                                console.log(response);
                                document.getElementById(signature).innerText = "0x"+response.slice(26);
                        }
                        // else we just parse everything as number
                        else {
                            console.log(response);
                            document.getElementById(signature).innerText = window.web3.utils.hexToNumberString(response);
                        }
                    } else {
                        document.getElementById(signature).innerText = "execution reverted";
                    }
                });
        } catch (err) {
            let error_string = err.toString();
            try {
                let error = error_string.substring(error_string.indexOf("{"));
                error = JSON.parse(error);
                document.getElementById(signature).innerText = error.originalError.message;
            } catch {
                document.getElementById(signature).innerText = err.message;
            }
        }
    }
    render() {
        let baseURL = this.props.baseCoin === 'ETH' ? "https://etherscan.io/address/" : "https://bscscan.com/address/"
        let DexTools = this.props.baseCoin === 'ETH' ? "https://www.dextools.io/app/ether/pair-explorer/" : "https://www.dextools.io/app/bsc/pair-explorer/"
        // let myAcc = this.props.account.substring(0, 6)+"..."+this.props.account.slice(this.props.account.length - 4);
        let myAcc = this.props.account
        // let newDecimals = this.getDecimalsNew();
        // let TokenAddressShort = this.props.token ? this.props.token.substring(0, 6)+"..."+this.props.token.slice(this.props.token.length - 4) : null;
        let parsed_contract = null
        let contract_code = this.props.contractcode
        
        return (
            <>
                <div className="my-4 md:my-8">
                    <div className="bg-gradient-to-t from-a-gradient to-a rounded py-4 px-3 flex flex-wrap">
                        <div className="flex-grow px-6 -mx-3">
                            <h4 className="mb-3 w-full">Contract Info</h4>
                            <div className="bg-disabled text-a-uidark rounded py-2 px-4 mb-4 md:mb-0">
                                <div className="flex flex-wrap items-center mb-2">
                                    <div className="w-full md:w-1/4">Name</div>
                                    <div className="font-mono text-xl">{this.props.TokenName ? this.props.TokenName : <span className="text-a-medium">n/a</span>}
                                        {this.props.TokenSymbol ? ' (' + this.props.TokenSymbol + ')' : <span className="text-a-medium">(n/a)</span>}</div>
                                </div>
                                <div className="flex flex-wrap items-start mb-2">
                                    <div className="w-full md:w-1/4">Token</div>
                                    <div className="font-mono text-xl leading-tight">{this.props.token ? this.props.token : <span className="text-a-medium">n/a</span>}<br/>
                                        {this.props.token ? <a className="text-xs font-sans" target="_blank" href={baseURL + this.props.token}>{this.props.baseCoin === 'ETH' ? 'etherscan.io ↗' : 'bscscan.com ↗'}</a> : ""}
                                        {this.props.token ? <a className="pl-2 text-xs font-sans" target="_blank" href={DexTools + this.props.tokenPair}>DexTools ↗</a> : ""}
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-center mb-1">
                                    <div className="w-full md:w-1/4">Decimals</div>
                                    <div className="font-mono text-xl">{this.props.TokenDecimals ? this.props.TokenDecimals : <span className="text-a-medium">n/a</span>}</div>
                                </div>
                                <div className="flex flex-wrap items-center mb-1">
                                    <div className="w-full md:w-1/4">TokenOwner</div>
                                    <div className="font-mono text-xl">{this.props.TokenOwner ? this.props.TokenOwner : <span className="text-a-medium">n/a</span>}</div>
                                </div>
                                <div className="flex flex-wrap items-center mb-1">
                                    <div className="w-full md:w-1/4">Total Supply</div>
                                    <div className="font-mono text-xl">{this.props.TokenSupply ? <NumberFormat displayType={'text'} thousandSeparator={true} value={this.props.TokenSupply} /> : <span className="text-a-medium">n/a</span>}</div>
                                </div>
                                {this.props.maxTXAmount ?
                                    <div className="flex flex-wrap items-center">
                                        <div className="w-full md:w-1/4">max. TX-Amount</div>
                                        <div className="font-mono text-xl"><NumberFormat displayType={'text'} thousandSeparator={false} value={this.props.maxTXAmount} />
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="flex-grow md:flex-grow-0 md:w-1/4 px-6 -mx-3">
                            <h4 className="mb-3 w-full">Status</h4>
                            <div className="rounded shadow-lg">
                                {this.props.liquidity !== true
                                ? <>
                                    <div className="bg-b-medium rounded-t py-4 px-4 text-lg font-bold flex justify-center whitespace-nowrap">No Liquidity</div>
                                    <div className="bg-b-light rounded-b py-4 px-4 text-lg flex justify-center whitespace-nowrap cursor-pointer" onClick={this.props.getLiquidityAvailable}>
                                        Check Liquidity
                                        {/*<div className="ball-triangle-path">
                                            <div/>
                                            <div/>
                                            <div/>
                                        </div>*/}
                                    </div>
                                    </>
                                : <>
                                    <div className="bg-c-dark rounded-t py-4 px-4 text-lg font-bold flex justify-center whitespace-nowrap">Liquidity added</div>
                                    <div className="bg-c-light rounded-b py-4 px-4 text-lg flex justify-center whitespace-nowrap">Ape now!</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4 md:my-8">
                    <div className="bg-gradient-to-t from-a-gradient to-a rounded py-4 px-3 flex flex-wrap">
                        <div className="w-full px-4">
                            <h4 className="mb-4 w-full">Contract Functions</h4>

                                {
                                    this.props.token_functions.map((item, index) => {
                                        const { signature, function_name, return_type } = item;
                                        return (
                                        <>
                                        <tr >
                                            <td className="py-2 pl-6 whitespace-nowrap"></td>
                                            <td>{signature}</td>
                                            <td>
                                            - {function_name.substring(0, 40)}
                                            </td>
                                            <td className="ml-4">
                                            <button className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.call_function(signature)}>exec</button>
                                            </td>
                                            
                                            <td><span className="ml-4" id={signature}></span>
                                            </td>
                                        </tr>
                                        </>
                                        )
                                    })
                                }
                                
                            <div className="my-8">
                                <h4 className="mb-4 w-full">Contract Source</h4>
                                {contract_code ? 
                                <pre className="overflow-x-auto shadow-lg bg-gray-700 text-gray-200 rounded text-xs p-2" dangerouslySetInnerHTML={{ __html: hljs.highlight('solidity', contract_code).value }} />
                                : ""}
                            </div>
                            <button className="rounded bg-a-uihover py-3 px-16 text-l font-bold hover:bg-a-uidefault shadow-lg w-30" onClick={this.props.setScam}>Set as Scam</button>
                                {this.props.scamresponse ? <div className="bg-b-medium rounded-t py-4 px-4 text-s font-bold w-30">{this.props.scamresponse}</div> : ""}
                        </div>
                    </div>
                </div>
            </>
        
        )
    }
}

export default Analyze;
