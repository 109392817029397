import React, { Component } from 'react';
import Web3 from 'web3';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Table } from "react-bootstrap";
import axios from 'axios';
import contract from './Tokens/TBA';
import contractUNI from './Tokens/UNI';
import contractUNIFactory from './Tokens/UNIFactory';
import minABI from './Tokens/minABI';
import Nav from './Components/Nav';
import Description from './Components/Description';
import Analyze from './Components/Analyze';
import AddressBar from './Components/AddressBar';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago';
TimeAgo.addDefaultLocale(en)

class App extends Component {
    constructor(props){
        super(props);

        this.appName = contract.name;

        this.onInputChangeUpdateField = this.onInputChangeUpdateField.bind(this);

        this.state = {
            tzAddress: "",
            inProgress: false,
            totalSupply: 0,
            liquidity: "false",
            routeraddress: null,
            uniFactory: null,
            tx: null,
            token: null,
            tokenPair: null,
            TokenDecimals: 0,
            token_functions: [],
            TokenSymbol: null,
            TokenSupply: 0,
            maxTXAmount: 0,
            TokenName: "",
            TokenOwner: "",
            network: 'Connecting',
            baseCoin: 'ETH',
            sitename: '',
            weth: "",
            web3contract: null,
            account: null,
            accounteth: null,
            buyethvalue: null,
            contracterror: null,
            scamresponse: null,
            defaultGasPrice: null,
            defaultGasLimit: null,
            minTokenOut: null,
            contractcode: null,
            contractslist: [],
            contractsFilteredlist: [],
            filterContractName: null,
            customFilteredList: [],
            contractslistBSC: [],
            menuOpen: false
        };
    }

    setNetwork = async () => {
        let networkName, that = this;
        let cAddress;
        let baseCoin;
        let weth;
        let sitename;
        let routeraddress;
        let uniFactory;

        await window.web3.eth.net.getId(function (err, networkId) {
            switch (networkId) {
                case 1:
                    networkName = "ETH";
                    cAddress = "0x6f5d36e0c7ffd6d16d253255eb57cc375b0f7669";
                    baseCoin = "ETH";
                    weth = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
                    routeraddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
                    sitename = "UniSwap V2";
                    uniFactory = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
                    break;
                case 2:
                    networkName = "Morden";
                    break;
                case "3":
                    networkName = "Ropsten";
                    break;
                case 4:
                    networkName = "Rinkeby";
                    break;
                case 42:
                    networkName = "Kovan";
                    break;
                case 56:
                    networkName = "BNB";
                    cAddress = "0xb99dc2e3433ae6eae6aba5ae52bcba8dd7110917";
                    baseCoin = "BNB";
                    weth = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
                    sitename = "PanCake v2";
                    routeraddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
                    break;
                default:
                    networkName = networkId;
            }
            that.setState({
                tzAddress: cAddress,
                network: networkName,
                baseCoin: baseCoin,
                weth: weth,
                sitename: sitename,
                routeraddress: routeraddress,
                uniFactory: uniFactory
            })
        });
    };

    setGasPrice = async () => {
        window.web3.eth.getGasPrice((err, price) => {
            //console.log(price);
            price = Web3.utils.fromWei(price, 'gwei'); // price.toNumber
            /*let newprice = Web3.utils.toBN(price);
            console.log(newprice);
            newprice = newprice.mul(2);
            console.log(newprice);*/


            if (!err) {
                console.log(price);
                this.setState({ defaultGasPrice: "autofill"}
                )
            }
        });
    };

    resetApp = () => {
      this.setState({
          transferDetail: {},
          defaultGasPrice: null,
      })
    };

    /*
    getContractInfo = async () => {
        let contractweb3 = new window.web3.eth.Contract(contract.abi, contract.address);
        let app = this;
        await contractweb3.methods.totalSupply().call()
            .then((response, err) => {
                if (response) {
                    app.setState({
                        totalSupply: response/10**18
                    });
                } else {
                    console.log(err);
                }
            });
    };*/

    // Change to Uniswap Router
    setFomoBuyContract = async () => {
        let app = this;
        let web3 = await new window.web3.eth.Contract(contractUNI.abi, app.state.routeraddress);
        await app.setState({
            web3contract: web3
        });
    };

    getLiquidityAvailable = async () => {
        let contractweb3 = new window.web3.eth.Contract(contractUNI.abi,this.state.routeraddress);
        let app = this;
        let path = [this.state.weth,this.state.token];
        try {
            await contractweb3.methods.getAmountsIn(1,path).call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            liquidity: true
                        });
                    } else {
                        app.setState({
                            liquidity: false
                        });
                    }
                });
        } catch {
            app.setState({
                liquidity: false
            });
            console.log("no liquidity");
        }
    };

    getPair = async () => {
        let contractweb3factory = new window.web3.eth.Contract(contractUNIFactory.abi, this.state.uniFactory);
        let app = this;
        try {
            await contractweb3factory.methods.getPair(this.state.token, this.state.weth).call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            tokenPair: response
                        });
                    } else {
                        app.setState({
                            tokenPair: null
                        });
                    }
                });
        } catch {
            app.setState({
                tokenPair: null
            });
            console.log("no pair found");
        }
    };

    getContractInfo = async () => {
        if (this.state.token !== null && this.state.token !== "") {
            Promise.all([
                await this.getName(),
                await this.getSupply(),
                await this.getDecimals(),
                await this.getTokenOwner(),
                await this.getPair(),
                await this.getContractFunctions(),
                await this.getTokenSymbol(),
                await this.getContractCode()
            ])
                .then(values =>
                    console.log("loaded Contract Info")
                );
            // Check Liquidity
            this.setState({
                scamresponse: null
            });
            await this.getLiquidityAvailable();
        }
    };

    getAccountInfo = async () => {
        try {
            let app = this;

            let accountlist = await window.web3.eth.getAccounts();
            if (accountlist.length > 0) {
                let accEth = Web3.utils.fromWei(await window.web3.eth.getBalance(accountlist[0]));
                app.setState({
                    account: accountlist[0],
                    accounteth: accEth
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    getTokenOwner = async () => {
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.owner().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenOwner: response
                        });
                    } else {
                        app.setState({
                            TokenOwner: 0
                        });
                    }
                });
        } catch (err) {
            //console.log(err);
        }
    };

    getDecimals = async () => {
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.decimals().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenDecimals: response
                        });
                    } else {
                        app.setState({
                            TokenDecimals: 0
                        });
                    }
                });
        } catch (err) {
            //console.log(err);
        }
    };
    
    getName = async () => {
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.name().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenName: response
                        });
                    } else {
                        app.setState({
                            TokenName: 0
                        });
                    }
                });
        } catch (err) {
            //console.log(err);
        }
    };

    getTokenSymbol = async () => {
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.symbol().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenSymbol: response
                        });
                    } else {
                        app.setState({
                            TokenSymbol: 0
                        });
                    }
                });
        } catch (err) {
            //console.log(err);
        }
    };

    getSupply = async () => {
        await this.getDecimals();
        try {
            let app = this;
            let contractweb3 = new window.web3.eth.Contract(minABI.abi, app.state.token);
            await contractweb3.methods.totalSupply().call()
                .then((response, err) => {
                    if (response) {
                        app.setState({
                            TokenSupply: Math.round(response / 10 ** this.state.TokenDecimals)
                        });
                    } else {
                        app.setState({
                            TokenSupply: 0
                        });
                    }
                });
        } catch (err) {
            //console.log(err);
        }
    };

    getContractFunctions = async () => {
        const instance = axios.create({
            baseURL: 'https://api.fomoape.com',
            timeout: 10000
        });

        const getContracts_url = `/contract_functions/`+ this.state.token;

        const data = await axios
            .all([instance.get(getContracts_url)])
            .then(
                axios.spread((getContractsResponse) => {
                    return { token_functions: getContractsResponse.data };
                })
            )
            .catch(error => {
                return { token_functions: [] };
            });

        this.setState({
            token_functions: data.token_functions[this.state.token]
        });
        console.log("getContractFunctions loaded");
    };

    setScam = async () => {
        let app = this;
        try {
            if ((this.state.token !== null || this.state.token !== "") && this.state.account !== null) {
                axios.post('https://api.fomoape.com/scam/' + this.state.token + '/' + this.state.account, {})
                    .then(function (response) {
                        console.log(response);
                        app.setState({
                            scamresponse: "Success"
                        });
                    })
                    .catch(function (error) {
                        app.setState({
                            scamresponse: "Failed - not authorized"
                        });
                        console.log(error);
                    });
            }
        } catch (err) {
            console.log(err);
        }
    };

    checkScam = async (address) => {
        let app = this;
        try {
            if ((this.state.token !== null || this.state.token !== "") && this.state.account !== null) {
                axios.post('https://api.fomoape.com/scamCheck/' + address + '/' + this.state.account, {})
                    .then(function (response) {
                        console.log(response);
                        app.setState({
                            scamresponse: "Success"
                        });
                    })
                    .catch(function (error) {
                        app.setState({
                            scamresponse: "Failed - not authorized"
                        });
                        console.log(error);
                    });
            }
        } catch (err) {
            console.log(err);
        }
    };

    getMinAmountBuy = async () => {
        let minAmountUser = this.state.minTokenOut ? this.state.minTokenOut : 1;
        let decimals = this.state.TokenDecimals;
        /*let bignumberAmount = new BigNumber(minAmountUser * 10 ** decimals);
        console.log("bignumber",bignumberAmount);*/
        let amount = (minAmountUser * 10 ** decimals);
        let fix2 = "0x"+amount.toString(16);
        console.log(amount,fix2);
        return fix2; // return it as string
    }

    sendBuy = async () => {
        await this.getContractInfo();
        let minTokenOut = await this.getMinAmountBuy();

        //let newGas = Web3.utils.toWei(this.state.defaultGasPrice, 'gwei');
        let EtherBN = Web3.utils.toWei(this.state.buyethvalue, 'ether');
        let transObj = {from: this.state.account, value: EtherBN, maxPriorityFeePerGas: null, maxFeePerGas: null}; // , gas: this.state.defaultGasLimit
        let app = this;
        let contractweb3 = app.state.web3contract;
		let path = [app.state.weth,app.state.token];

        await contractweb3.methods.swapExactETHForTokens(minTokenOut, path, app.state.account, 1665443910).estimateGas({
            from: app.state.account,
            value: EtherBN,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null
            }, function(error, estimatedGas) {
				if (error) {
					app.setState({
						contracterror: "Contract ERROR! Take care, this transaction might fail!"
					});
					console.log("error");
					app.buyError();
				}
				else {
                    app.setState({
                        contracterror: null
                    });

                    contractweb3.methods.swapExactETHForTokens(minTokenOut,path,app.state.account,1665443910).send(transObj)
                        .then((response, err) => {
                            if (response) {
                                console.log(response);
                                app.setState({
                                    tx: response.tx,
                                    inProgress: false
                                });
                            } else {
                                console.log(err);
                            }
                    });
                }
            }
        );
    };
	
	buyError = async () => {
        await this.getContractInfo();
        let minTokenOut = await this.getMinAmountBuy();
        let contractweb3 = this.state.web3contract;
        //let newGas = Web3.utils.toWei(this.state.defaultGasPrice, 'gwei');
        let EtherBN = Web3.utils.toWei(this.state.buyethvalue, 'ether');
        //let transObj = { from: this.state.account, value: EtherBN, gasPrice: newGas }; // , gas: this.state.defaultGasLimit
        let app = this;
		let path = [this.state.weth,this.state.token];
        let transObjGas = {
            from: this.state.account, value: EtherBN, gas: 700000,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null };
        contractweb3.methods.swapExactETHForTokens(minTokenOut,path,this.state.account,1665443910).send(transObjGas)
		//contractweb3.methods.ethToTokenFeeETH(1,path,1665443910).send(transObjGas)
			.then((response, err) => {
				if (response) {
					console.log(response);
					app.setState({
						tx: response.tx,
						inProgress: false
					});
				} else {
					console.log(err);
				}
		});
	}

    onInputChangeUpdateField = async (name, value) => {
        if (name === "token") {
            this.setState({
                token: value
            }, () => this.getContractInfo());
        }
        else if (name === "account") {
            this.setState({
                account: value
            }, () => this.getAccountInfo());
        }
        else {
                this.setState({
                    [name]: value
                });
            }
    };

    getParameterByName = (name) => {
        let url = window.location.href;
        url = url.toLowerCase();
        name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    getParamToken = () => {
        this.setState({
            token: this.getParameterByName('token')
        });
    };

    getParamCustomFilter = () => {
        this.setState({
            filterContractName: this.getParameterByName('contractname')
        });
    };

    getDataContractList = async () => {
        try {
            const instance = axios.create({
                baseURL: 'https://api.fomoape.com',
                timeout: 10000
            });

            const getContracts_url = `/getContracts/1`;

            const data = await axios
                .all([instance.get(getContracts_url)])
                .then(
                    axios.spread((getContractsResponse) => {
                        return { contractslist: getContractsResponse.data };
                    })
                )
                .catch(error => {
                    return { contractslist: []};
                });


            if (data.contractslist.length > 5) {  
                this.setState({
                    contractslist: data.contractslist
                });
            }
            console.log("contractslist - loaded");
        }
        catch (err) {
            console.log("error loading contractslist");
        }
        // contractslist: data.contractslist.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
    };

    getDataContractFilteredList = async () => {
        try {
            const instance = axios.create({
                baseURL: 'https://api.fomoape.com',
                timeout: 10000
            });

            const getContracts_url = `/getContractsFiltered/1`;

            const data = await axios
                .all([instance.get(getContracts_url)])
                .then(
                    axios.spread((getContractsResponse) => {
                        return { contractsFilteredlist: getContractsResponse.data };
                    })
                )
                .catch(error => {
                    return { contractsFilteredlist: [] };
                });

                if (data.contractsFilteredlist != []) {  
                    this.setState({
                        contractsFilteredlist: data.contractsFilteredlist
                    });
                }
            console.log("contractsFilteredlist - loaded");
        }
        catch (err) {
            console.log("error loading contractsFilteredlist");
        }
    };


    getDataContractCustomFilteredList = async () => {
        // set State for Filtering
        this.getParamCustomFilter();
        if (this.state.filterContractName) {
            const instance = axios.create({
                baseURL: 'https://api.fomoape.com',
                timeout: 10000
            });

            const getContracts_url = `/getCustomContractsFiltered/`+ this.state.filterContractName;

            const data = await axios
                .all([instance.get(getContracts_url)])
                .then(
                    axios.spread((getContractsResponse) => {
                        return { customFilter: getContractsResponse.data };
                    })
                )
                .catch(error => {
                    return { customFilter: [] };
                });

            this.setState({
                customFilteredList: data.customFilter
            });
            console.log("Custom Filtered loaded");
        }
    };

    getContractCode = async () => {
        const instance = axios.create({
            baseURL: 'https://api.etherscan.io/',
            timeout: 10000
        });

        const getCode_URL = `/api?module=contract&action=getsourcecode&address=` + this.state.token + `&apikey=GY6QZ6C695REXUY2YZVRANYBEHIGIJSHS9`;
        //console.log(getCode_URL);

        const data = await axios
            .all([instance.get(getCode_URL)])
            .then(
                axios.spread((getCodeResponse) => {
                    //console.log(getCodeResponse);
                    return { contractcode: getCodeResponse.data.result[0].SourceCode };
                })
            )
            .catch(error => {
                return { contractcode: null };
            });

        this.setState({
            contractcode: data.contractcode
        });
    };

    setToken = async (address) => {
        this.setState({
            token: address
        },() => this.getContractInfo());
    }

    getDataContractListBSC = async () => {
        const instance = axios.create({
            baseURL: 'https://api.fomoape.com',
            timeout: 10000
        });

        const getContracts_url = `/getContracts/BSC`;

        const data = await axios
            .all([instance.get(getContracts_url)])
            .then(
                axios.spread((getContractsResponse) => {
                    return { contractslistBSC: getContractsResponse.data };
                })
            )
            .catch(error => {
                return { contractslistBSC: []};
            });

        this.setState({
            contractslistBSC: data.contractslistBSC
        });
        // contractslist: data.contractslist.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
    };

    componentDidMount = async () => {
        document.title = "FomoApe";
        let that = this;
        if (window.web3) {
            this.getParamToken();
            window.web3 = new Web3(window.ethereum);
            await that.setNetwork();
            //console.log(that.state.baseCoin,that.state.tzAddress);
            await that.setGasPrice();
            //await this.getContractInfo();

            let accountlist = await window.web3.eth.getAccounts();
            if (accountlist.length > 0) {
                let accEth = Web3.utils.fromWei(await window.web3.eth.getBalance(accountlist[0]));
                this.setState({
                    account: accountlist[0],
                    accounteth: accEth
                });
            }
            Promise.all([that.setFomoBuyContract()]);
            await this.getContractInfo();
        }
        await that.getDataContractList();
        await that.getDataContractFilteredList();
        await that.getDataContractCustomFilteredList();
        //await that.getDataContractListBSC();
        this.getParamToken();
        setInterval(() => that.getDataContractList(), 30000);
        setInterval(() => that.getDataContractFilteredList(), 30000);
    }

    render() {
        return (
            <>
                <Router basename={'/'} >
                    <Switch>
                        <Route exact path="/">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <Description baseCoin={this.state.baseCoin} sitename={this.state.sitename} />
                                    <AddressBar account={this.state.account}
                                        baseCoin={this.state.baseCoin}
                                        accounteth={this.state.accounteth}
                                        defaultGasPrice={this.state.defaultGasPrice}
                                        defaultGasLimit={this.state.defaultGasLimit}
                                        tokenPair={this.state.tokenPair}
                                        token={this.state.token}
                                        TokenName={this.state.TokenName}
                                        TokenDecimals={this.state.TokenDecimals}
                                        TokenOwner={this.state.TokenOwner}
                                        TokenSupply={this.state.TokenSupply}
                                        minTokenOut={this.state.minTokenOut}
                                        sendBuy={this.sendBuy}
                                        getLiquidityAvailable={this.getLiquidityAvailable}
                                        liquidity={this.state.liquidity}
                                        reloadContract={this.getContractInfo}
                                        onInputChangeUpdateField={this.onInputChangeUpdateField}
                                        contracterror={this.state.contracterror} />
                                </div>
                            </>
                        </Route>
                        <Route exact path="/analyze">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <Description baseCoin={this.state.baseCoin} sitename={this.state.sitename} />
                                    <Analyze account={this.state.account}
                                        baseCoin={this.state.baseCoin}
                                        accounteth={this.state.accounteth}
                                        defaultGasPrice={this.state.defaultGasPrice}
                                        defaultGasLimit={this.state.defaultGasLimit}
                                        tokenPair={this.state.tokenPair}
                                        token={this.state.token}
                                        TokenName={this.state.TokenName}
                                        token_functions={this.state.token_functions}
                                        TokenSymbol={this.state.TokenSymbol}
                                        TokenDecimals={this.state.TokenDecimals}
                                        TokenOwner={this.state.TokenOwner}
                                        TokenSupply={this.state.TokenSupply}
                                        maxTXAmount={this.state.maxTXAmount}
                                        minTokenOut={this.state.minTokenOut}
                                        sendBuy={this.sendBuy}
                                        setScam={this.setScam}
                                        getLiquidityAvailable={this.getLiquidityAvailable}
                                        liquidity={this.state.liquidity}
                                        reloadContract={this.getContractInfo}
                                        onInputChangeUpdateField={this.onInputChangeUpdateField}
                                        contractcode={this.state.contractcode} 
                                        scamresponse={this.state.scamresponse}
                                        contracterror={this.state.contracterror} />
                                </div>
                            </>
                        </Route>
                        <Route exact path="/about">
                            <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <div className="bg-brand rounded-t py-4 px-6 mt-4 md:mt-8 border-b-2 border-a-extradark">
                                        <h1 className="font-bold text-3xl text-white mb-12">For Traders</h1>
                                    </div>
                                    <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pt-4 pb-12 px-6 mb-4 md:mb-8 text-lg">
                                        <div className="flex flex-wrap w-full items-start mb-8">
                                            <h1 className="font-bold text-xl mb-4 text-white w-full lg:w-3/12">Why FomoApe?</h1>
                                            <ul className="list-disc list-inside w-full lg:w-8/12">
                                                <li>Snipe new tokens as soon as liquidity is added</li>
                                                <li>Find the newest token contracts</li>
                                                <li>Beat other market participants</li>
                                                <li>Works on Ethereum blockchain as well as Binance smartchain</li>
                                            </ul>
                                        </div>
                                        <div className="flex flex-wrap w-full items-start mb-8">
                                            <h2 className="font-bold text-xl mb-4 text-white w-full lg:w-3/12">How does it work?</h2>
                                            <ul className="list-disc list-inside w-full lg:w-8/12">
                                                <li>Wait for liquidity being added</li>
                                                <li>Enter ETH amount to buy, gas price and minimum token output</li>
                                                <li>Buy the token and confirm in Metamask<br/><span className="text-xs">A 5% fee is applied on successful transactions</span></li>
                                            </ul>
                                        </div>
                                        <div className="flex flex-wrap w-full items-start">
                                            <h2 className="font-bold text-xl mb-4 text-white w-full lg:w-3/12">Caution</h2>
                                            <div className="w-full lg:w-8/12">Most ill-formed transactions (e.g. wrong contract) fail and you don't loose anyting. If you enter the wrong address or ETH amount, we can do nothing for you.</div>
                                        </div>
                                    </div>
                                    <div className="bg-brand to-a-uihover rounded-t py-4 px-6 mt-4 md:mt-8 border-b-2 border-a-extradark">
                                        <h1 className="font-bold text-3xl text-white mb-12">For Project Maintainers</h1>
                                    </div>
                                    <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pt-4 pb-12 px-6 mb-4 md:mb-8 text-lg">
                                        <div className="flex flex-wrap w-full items-start">
                                            <h1 className="font-bold text-xl mb-4 text-white w-full lg:w-3/12">Why FomoApe?</h1>
                                            <ul className="list-disc list-inside w-full lg:w-8/12">
                                                <li>Build hype for your project by enabling good trades when starting your project</li>
                                                <li>Enable less experiences traders to buy into your project right on release</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                        </Route>
                        <Route exact path="/customfiltered">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <div className="bg-eth rounded-t py-4 px-6 mt-4 md:mt-8">
                                        <h1 className="w-full mb-12 text-3xl text-white font-bold">Filtered bytecode contracts for {this.state.filterContractName}</h1>
                                        <a href={"./#/customfiltered/?contractname=" + this.state.filterContractName} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.getDataContractCustomFilteredList()}>reload</a>
                                    </div>
                                    <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pb-4 px-6 mb-4 md:mb-8">
                                            <div className="overflow-x-auto -mx-6">
                                                <Table className="mb-9 w-full">
                                                    <thead className="border-b-2 border-t-2 border-a-extradark text-left">
                                                    <tr>
                                                        <th className="py-2 pl-6 whitespace-nowrap">Time ago</th>
                                                        <th>Name</th>
                                                        <th>Symbol</th>
                                                        <th>Address</th>
                                                        <th>Decimals</th>
                                                        <th className="whitespace-nowrap">Total Supply</th>
                                                        <th>Owner</th>
                                                        <th className="whitespace-nowrap">ETH</th>
                                                        <th className="pr-6"/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.customFilteredList.map((item, index) => {
                                                    const { time, address, name, symbol, decimals, _maxTXAmount, owner, totalSupply, liquidity, scam, owner_eth, contract_eth, verified, open_trading_function, tax_buy_percentage, tax_sell_percentage, max_transfer, max_wallet} = item;
                                                    let bg_row = "";
                                                    if (liquidity == 1 || scam == 1) { bg_row = " bg-eth-liquidity"; }
                                                    let scam_row = "";
                                                    if (scam == 1) { scam_row = "SCAM"; }
                                                    return (
                                                        <>
                                                            <tr
                                                                className={ `${bg_row}`}
                                                                key={`${address}-${name}`}
                                                            >
                                                                <td className="py-2 pl-6 whitespace-nowrap"><ReactTimeAgo date={Date.parse(time)} locale="en" /></td>
                                                                <td>{name ? name.substring(0, 25) : ""}</td>
                                                                <td><a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs"
                                                                    href={"https://twitter.com/search?f=live&q=$" + symbol}
                                                                    target="_blank"
                                                                    title="twitter search">
                                                                    T</a>
                                                                    &nbsp;{symbol ? symbol.substring(0, 12) : ""}
                                                                </td>
                                                                <td><a href={"https://etherscan.io/address/" + address + "#code"} target="_blank">
                                                                    {" "}
                                                                    {address
                                                                        ? `${address.substring(0, 6)}...${address.slice(address.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{decimals}</td>
                                                                <td>{totalSupply / 10 ** decimals}</td>
                                                                <td><a href={"https://etherscan.io/address/" + owner} target="_blank">
                                                                    {" "}
                                                                    {owner
                                                                        ? `${owner.substring(0, 6)}...${owner.slice(owner.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{(owner_eth / 10 ** 18).toFixed(3)} ETH</td>
                                                                <td><a href={"./#/?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>TRADE</a>&nbsp;
                                                                    <a href={"./#/analyze?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>A</a>&nbsp;
                                                                    {verified == 1
                                                                        ? <a className="bg-a-green hover:bg-a-uidefault rounded py-1 px-2 text-xs">V</a>
                                                                        : <a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.checkScam(address)}>C</a>}


                                                                </td>
                                                            </tr>
                                                            <tr
                                                                className={`${bg_row}`}
                                                            >
                                                                <td className="pl-4">{scam_row}</td>
                                                                <td colSpan="3">{open_trading_function}</td>
                                                                <td>maxtx: </td>
                                                                 <td>{max_transfer/10**decimals} ({((max_transfer/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax buy: {tax_buy_percentage}%</td>
                                                            </tr>
                                                            <tr
                                                                className={"border-b border-a-extradark" + `${bg_row}`}
                                                            >
                                                            <td></td>
                                                                <td colSpan="3"></td>
                                                                 <td>max_wallet: </td>
                                                                 <td>{max_wallet/10**decimals} ({((max_wallet/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax sell: {tax_sell_percentage}%</td>
                                                            </tr>
                                                        </>
                                                        
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Route>
                        <Route exact path="/filtered">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <div className="bg-eth rounded-t py-4 px-6 mt-4 md:mt-8">
                                        <h1 className="w-full mb-12 text-3xl text-white font-bold">New ETH bytecode contracts</h1>
                                        <h2>list contains tokens without liquidity, owner has &gt; 0.4 eth and it's not automarked as scam</h2>
                                    </div>
                                    <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pb-4 px-6 mb-4 md:mb-8">
                                            <div className="overflow-x-auto -mx-6">
                                                <Table className="mb-9 w-full">
                                                    <thead className="border-b-2 border-t-2 border-a-extradark text-left">
                                                    <tr>
                                                        <th className="py-2 pl-6 whitespace-nowrap">Time ago</th>
                                                        <th>Name</th>
                                                        <th>Symbol</th>
                                                        <th>Address</th>
                                                        <th>Decimals</th>
                                                        <th className="whitespace-nowrap">Total Supply</th>
                                                        <th>Owner</th>
                                                        <th className="whitespace-nowrap">ETH</th>
                                                        <th className="pr-6"/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.contractsFilteredlist.map((item, index) => {
                                                    const { time, address, name, symbol, decimals, _maxTXAmount, owner, totalSupply, liquidity, scam, owner_eth, contract_eth, verified, open_trading_function, tax_buy_percentage, tax_sell_percentage, max_transfer, max_wallet} = item;
                                                    let bg_row = "";
                                                    if (liquidity == 1 || scam == 1) { bg_row = " bg-eth-liquidity"; }
                                                    let scam_row = "";
                                                    if (scam == 1) { scam_row = "SCAM"; }
                                                    return (
                                                        <>
                                                            <tr
                                                                className={ `${bg_row}`}
                                                                key={`${address}-${name}`}
                                                            >
                                                                <td className="py-2 pl-6 whitespace-nowrap"><ReactTimeAgo date={Date.parse(time)} locale="en" /></td>
                                                                <td>{name ? name.substring(0, 25) : ""}</td>
                                                                <td><a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs"
                                                                    href={"https://twitter.com/search?f=live&q=$" + symbol}
                                                                    target="_blank"
                                                                    title="twitter search">
                                                                    T</a>
                                                                    &nbsp;{symbol ? symbol.substring(0, 12) : ""}
                                                                </td>
                                                                <td><a href={"https://etherscan.io/address/" + address + "#code"} target="_blank">
                                                                    {" "}
                                                                    {address
                                                                        ? `${address.substring(0, 6)}...${address.slice(address.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{decimals}</td>
                                                                <td>{totalSupply / 10 ** decimals}</td>
                                                                <td><a href={"https://etherscan.io/address/" + owner} target="_blank">
                                                                    {" "}
                                                                    {owner
                                                                        ? `${owner.substring(0, 6)}...${owner.slice(owner.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{(owner_eth / 10 ** 18).toFixed(3)} ETH</td>
                                                                <td><a href={"./#/?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>TRADE</a>&nbsp;
                                                                    <a href={"./#/analyze?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>A</a>&nbsp;
                                                                    {verified == 1
                                                                        ? <a className="bg-a-green hover:bg-a-uidefault rounded py-1 px-2 text-xs">V</a>
                                                                        : <a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.checkScam(address)}>C</a>}


                                                                </td>
                                                            </tr>
                                                            <tr
                                                                className={`${bg_row}`}
                                                            >
                                                                <td className="pl-4">{scam_row}</td>
                                                                <td colSpan="3">{open_trading_function}</td>
                                                                <td>maxtx: </td>
                                                                 <td>{max_transfer/10**decimals} ({((max_transfer/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax buy: {tax_buy_percentage}%</td>
                                                            </tr>
                                                            <tr
                                                                className={"border-b border-a-extradark" + `${bg_row}`}
                                                            >
                                                            <td></td>
                                                                <td colSpan="3"></td>
                                                                 <td>max_wallet: </td>
                                                                 <td>{max_wallet/10**decimals} ({((max_wallet/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax sell: {tax_sell_percentage}%</td>
                                                            </tr>
                                                        </>
                                                        
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Route>

                        <Route exact path="/contract">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                    <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                        <div className="bg-eth rounded-t py-4 px-6 mt-4 md:mt-8">
                                        <h1 className="w-full mb-12 text-3xl text-white font-bold">New ETH bytecode contracts</h1>
                                    </div>
                                        <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pb-4 px-6 mb-4 md:mb-8">
                                            <div className="overflow-x-auto -mx-6">
                                                <Table className="mb-9 w-full">
                                                    <thead className="border-b-2 border-t-2 border-a-extradark text-left">
                                                    <tr>
                                                        <th className="py-2 pl-6 whitespace-nowrap">Time ago</th>
                                                        <th>Name</th>
                                                        <th>Symbol</th>
                                                        <th>Address</th>
                                                        <th>Decimals</th>
                                                        <th className="whitespace-nowrap">Total Supply</th>
                                                        <th>Owner</th>
                                                        <th className="whitespace-nowrap">ETH</th>
                                                        <th className="pr-6"/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.contractslist.map((item, index) => {
                                                    const { time, address, name, symbol, decimals, _maxTXAmount, owner, totalSupply, liquidity, scam, owner_eth, contract_eth, verified, open_trading_function, tax_buy_percentage, tax_sell_percentage, max_transfer, max_wallet} = item;
                                                    let bg_row = "";
                                                    if (liquidity == 1 || scam == 1) { bg_row = " bg-eth-liquidity"; }
                                                    let scam_row = "";
                                                    if (scam == 1) { scam_row = "SCAM"; }
                                                    return (
                                                        <>
                                                            <tr
                                                                className={ `${bg_row}`}
                                                                key={`${address}-${name}`}
                                                            >
                                                                <td className="py-2 pl-6 whitespace-nowrap"><ReactTimeAgo date={Date.parse(time)} locale="en" /></td>
                                                                <td>{name ? name.substring(0, 25) : ""}</td>
                                                                <td><a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs"
                                                                    href={"https://twitter.com/search?f=live&q=$" + symbol}
                                                                    target="_blank"
                                                                    title="twitter search">
                                                                    T</a>
                                                                    &nbsp;{symbol ? symbol.substring(0, 12) : ""}
                                                                </td>
                                                                <td><a href={"https://etherscan.io/address/" + address + "#code"} target="_blank">
                                                                    {" "}
                                                                    {address
                                                                        ? `${address.substring(0, 6)}...${address.slice(address.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{decimals}</td>
                                                                <td>{totalSupply / 10 ** decimals}</td>
                                                                <td><a href={"https://etherscan.io/address/" + owner} target="_blank">
                                                                    {" "}
                                                                    {owner
                                                                        ? `${owner.substring(0, 6)}...${owner.slice(owner.length - 4)}`
                                                                        : ""}</a></td>
                                                                <td>{(owner_eth / 10 ** 18).toFixed(3)} ETH</td>
                                                                <td><a href={"./#/?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>TRADE</a>&nbsp;
                                                                    <a href={"./#/analyze?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>A</a>&nbsp;
                                                                    {verified == 1
                                                                        ? <a className="bg-a-green hover:bg-a-uidefault rounded py-1 px-2 text-xs">V</a>
                                                                        : <a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.checkScam(address)}>C</a>}


                                                                </td>
                                                            </tr>
                                                            <tr
                                                                className={`${bg_row}`}
                                                            >
                                                                <td className="pl-4">{scam_row}</td>
                                                                <td colSpan="3">{open_trading_function}</td>
                                                                <td>maxtx: </td>
                                                                 <td>{max_transfer/10**decimals} ({((max_transfer/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax buy: {tax_buy_percentage}%</td>
                                                            </tr>
                                                            <tr
                                                                className={"border-b border-a-extradark" + `${bg_row}`}
                                                            >
                                                            <td></td>
                                                                <td colSpan="3"></td>
                                                                 <td>max_wallet: </td>
                                                                 <td>{max_wallet/10**decimals} ({((max_wallet/totalSupply)*100).toFixed(2)}%)</td>
                                                                 <td colSpan="3">tax sell: {tax_sell_percentage}%</td>
                                                            </tr>
                                                        </>
                                                        
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Route>

                        <Route exact path="/contractbsc">
                            <>
                                <Nav appName={this.appName} network={this.state.network} />
                                <div className="text-white max-w-7xl mx-auto px-4 sm:px-6">
                                    <div className="bg-binance rounded-t py-4 px-6 mt-4 md:mt-8">
                                        <h1 className="w-full mb-12 text-3xl text-white font-bold">New BSC bytecode contracts</h1>
                                    </div>
                                        <div className="bg-gradient-to-t from-a-gradient to-a rounded-b pb-4 px-6 mb-4 md:mb-8">
                                            <div className="overflow-x-auto -mx-6">
                                                <Table className="mb-8 w-full">
                                                    <thead className="border-b-2 border-t-2 border-a-extradark text-left">
                                                    <tr>
                                                        <th className="py-2 pl-6 whitespace-nowrap">Time ago</th>
                                                        <th>Name</th>
                                                        <th>Symbol</th>
                                                        <th>Address</th>
                                                        <th>Decimals</th>
                                                        <th className="whitespace-nowrap">Total Supply</th>
                                                        <th className="whitespace-nowrap">Max. TX Amount</th>
                                                        <th>Owner</th>
                                                        <th className="pr-6"/>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.contractslistBSC.map((item, index) => {
                                                            const { time, address, name, symbol, decimals, _maxTXAmount, owner, totalSupply } = item;
                                                            return (
                                                                <tr
                                                                    className="border-b border-a-extradark whitespace-nowrap"
                                                                    key={`${address}-${name}`}
                                                                >
                                                                    <td className="py-2 pl-6 whitespace-nowrap"><ReactTimeAgo date={time} locale="en"/></td>
                                                                    <td>{name}</td>
                                                                    <td><a className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs"
                                                                           href={"https://twitter.com/search?f=live&q=$" + symbol}
                                                                           target="_blank"
                                                                           title="twitter search">
                                                                        T</a>
                                                                        &nbsp;{symbol ? symbol.substring(0, 12) : ""}
                                                                    </td>
                                                                    <td><a href={"https://bscscan.com/address/" + address + "#code"} target="_blank">
                                                                        {" "}
                                                                        {address
                                                                            ? `${address.substring(0, 6)}...${address.slice(address.length - 4)}`
                                                                            : ""}</a></td>
                                                                    <td>{decimals}</td>
                                                                    <td>{totalSupply}</td>
                                                                    <td>{_maxTXAmount}</td>
                                                                    <td className="word-break">
                                                                        {" "}
                                                                        {owner
                                                                            ? `${owner.substring(0, 6)}...${owner.slice(owner.length - 4)}`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="pr-6"><a href={"./#/?token=" + address} className="bg-a-uihover hover:bg-a-uidefault rounded py-1 px-2 text-xs" onClick={async () => await this.setToken(address)}>TRADE</a></td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Route>
                    </Switch>
                </Router>
            </>
        )
    }
}

export default App;
