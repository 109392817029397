import React from 'react';

function InputField(props) {
    let field = props.name;
    let placeholder = props.placeholder;
    let addon = props.addon;

    let handleChange = e => {
        props.onInputChangeUpdateField(field, e.target.value);
    };

    return (
        <input defaultValue={props.default} onInput={handleChange}
            placeholder={placeholder} className="bg-white rounded py-2 px-4 text-a-uidark shadow-lg font-mono text-xl w-full focus:outline-none focus:ring focus:ring-a-uidark" type="text"/>
    )
}

export default InputField;
